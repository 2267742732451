.main {
    position: relative;
    width: 100%;
}

.aside {
    position: absolute;
    left: 20px;
    top: 20px;
    height: calc(100% - 40px/*top and bottom margins*/);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 340px;
    min-width: 340px;
    @media (max-width: 760px) {
        left: calc(50% - 340px/2);
    }
}

// Placeholder styles

.asideRight {
    position: absolute;
    right: 20px;
    top: 20px;
    height: calc(100% - 40px/*top and bottom margins*/);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 340px;
    min-width: 340px;
    @media (max-width: 760px) {
        left: calc(50% - 340px/2);
    }
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}