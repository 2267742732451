.legend {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px 65px 20px 20px;
  width: 340px;
  /* max-height: 30%; */
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1100;

  &.closed {
    width: 50px;
    height: 50px;
    padding: 0;
    border-radius: 50%;
    flex-shrink: 0;
    background-image: url("../../../public/dots.png");
    background-size: contain;
    background-position: center;
    border: none;
    background-color: white;
    cursor: pointer;
    .container {
      display: none;
    }
  }
}

.bottomCheckbox {
  display: flex;
  justify-content: space-between;
}

.close {
  width: 16px;
  height: 16px;
  background-image: url("../../../public/close.svg");
  background-size: contain;
  background-position: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 20px;
}

@media (min-height: 871px) {
  .legend {
    max-height: 30%;
  }
}
@media (min-height: 500px) and (max-height: 870px) {
  .legend {
    max-height: 40%;
  }
}
@media (max-height: 389px) {
  .legend {
    display: none;
  }
}
