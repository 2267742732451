@import '../../styles/variables.scss';

.markdownTab {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100% - 15px - 30px);
    padding: 0 30px;
    margin-top: 15px;
  
    &::-webkit-scrollbar {
        background-color: #fff;
        width: 5px;
        border-radius: 20px;
    }
    &::-webkit-scrollbar-button {
        display: none;
    }
    &::-webkit-scrollbar-track {
        display: none;
    }
    &::-webkit-scrollbar-track-piece {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #2C2C2C;
    }
}

.markdownTab div,
.markdownTab p,
.markdownTab span,
.markdownTab a,
.markdownTab ul,
.markdownTab li {
    margin-bottom: 0.8em;
    margin-top: 0.3em;
}

.markdownTab h1, h2, h3, h4 {
    margin-bottom: 1em;
    margin-top: 0.4em;
    font-family: MAIN;
}

@media (max-width: 760px) {
    .markdownTab {
        max-width: 100vw;
        border-radius: 0;
    }
}

  
.container {
    z-index: 10001;
    border-radius: 12px;
    background-color: $main-bg-color;
    overflow: hidden;

    &.closed {
        display: none;
    }
}

.close {
    width: 16px;
    height: 16px;
    background-image: url("../../../public/close.svg");
    background-size: contain;
    background-position: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
}
