a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    text-decoration: underline;
  }
}

.header {
  background-color: #151515;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 0 20px;
  height: 50px;
}

.logoContainer {
  font-family: "LOGO";
  font-size: 17px;
  flex: 1 1 75%;
}

.logo {
  background-image: url("../../../public/logo.png");
  background-size: contain;
  background-position: center;
  display: block;
  background-repeat: no-repeat;
  height: 50px;
  min-width: 155px;
  max-width: 300px;
  padding-top: 10px;
}

.menu {
  font-family: "MAIN BOLD";
  flex: 1 1 25%;
  padding: 0;
  display: flex;
  list-style-type: none;
  justify-content: space-between;
}

.search {
  flex: 1 1 50%;
  text-align: center;

  input {
    color: #fff;
    background-color: transparent;
    border: none;
    font-size: 17px;

    &::placeholder {
      color: #fff;
    }

    &:focus {
      outline: none;
    }
  }
}

.burgerMenu {
  width: 30px;
  height: 20px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
}

.burgerMenuItem {
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.close {
  display: none;
}

.searchBtn {
  display: none;
}

@media (max-width: 1024px) {
  .header {
    position: relative;
  }

  .menu {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10002;
    background-color: #000;
    left: 0;
    bottom: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.open {
      display: flex;
    }

    a {
      font-size: 30px;
      margin-bottom: 20px;
      display: block;
    }
  }

  .close {
    background-image: url("../../../public/closeWhite.svg");
    background-position: center;
    background-size: contain;
    width: 30px;
    height: 30px;
    // transform: rotate(45deg);
    transition: 0.3s;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10002;
    color: #fff;
    display: none;
  }

  .open + .close {
    display: block;
  }

  .burgerMenu {
    display: flex;
  }

  .search {
    display: none;
    &.open {
      position: absolute;
      background-color: #fff;
      height: 80%;
      display: flex;
      align-items: center;
      padding-left: 5px;

      input {
        color: #000;
      }
    }
  }

  .searchBtn {
    display: block;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    background-image: url("../../../public/search.png");
    background-position: center;
    background-size: contain;
    margin-right: 10px;
  }
}
