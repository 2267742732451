@import '../../styles/variables.scss';

$defaul-radio-color: #000;

.wrapper {
    margin-bottom: 15px;
}

input[type='radio'].radio {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 4px;
    left: 0;
    opacity: 0;

    &:checked+.label:after {
        display: inline-block;
    }

    &:disabled+.title:before {
        filter: grayscale(100%);
    }
}

.label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 40px;
    margin-right: 0;
    line-height: 18px;
    user-select: none;

    &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 50%;
        background-color: transparent;
        border-width: 1px;
        border-style: solid;
        border-color: $defaul-radio-color;
    }

    &.antiWar:before {
        border-color: $antiwar-protest-color;
    }

    &.politicalDemonstrations:before {
        border-color: $political-demonstration-color;
    }

    &.mixed:before {
        border-color: $mixed-protest-color;
    }

    &.supportEvent:before {
        border-color: $support-event-color;
    }

    &:after {
        content: '';
        display: none;
        width: 10px;
        height: 10px;
        position: absolute;
        left: 3px;
        bottom: 3px;
        border-radius: 50%;
        background-color: $defaul-radio-color;
    }

    &.antiWar:after {
        background-color: $antiwar-protest-color;
    }

    &.politicalDemonstrations:after {
        background-color: $political-demonstration-color;
    }

    &.mixed:after {
        background-color: $mixed-protest-color;
    }

    &.supportEvent:after {
        background-color: $support-event-color;
    }

    &:hover:before {
        filter: brightness(120%);
    }
}
