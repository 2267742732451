$main-bg-color: #fff;
$main-input-bg-color: #efefef;
$main-input-color: #2c2c2c;
$main-label-color: #6e6f70;
$secondary-label-color: #989898;

$main-error-color: #da4e4e;
$secondary-error-color: #d73232;

$antiwar-protest-color: #7ea2ff;
$political-demonstration-color: #d73232;
$mixed-protest-color: #ecd931;
$support-event-color: #ffb67e;
