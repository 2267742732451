@import "../../styles/variables.scss";

.wrapper {
  position: relative;
  width: 100%;
  margin-top: 15px;

  &:not(.error) .input:focus,
  & .input:not(:placeholder-shown) {
    outline: 1px solid $main-input-bg-color;
    background-color: $main-bg-color;
  }
}

.input {
  font-family: MAIN;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: $main-input-bg-color;
  color: $main-input-color;
  width: 100%;
  display: block;

  &::-webkit-input-placeholder {
    color: transparent;
  }

  &:focus + .label,
  &:not(:placeholder-shown) + .label {
    top: -12px;
    background-color: $main-bg-color;
    font-size: 12px;
  }
}

.textarea {
  resize: vertical;
}

.label {
  position: absolute;
  top: 10px;
  line-height: 20px;
  left: 10px;
  padding: 0 5px;
  color: $main-label-color;
  font-size: 14px;
  transition: top 0.3s, font-size 0.3s;
  cursor: text;
}

.error {
  .input {
    outline: 1px solid $secondary-error-color;
    background-color: $main-bg-color;
  }

  .label {
    color: $main-error-color;
  }

  .errorText {
    display: block;
  }
}

.errorText {
  display: none;
  color: $main-error-color;
  font-size: 12px;
  line-height: 20px;
}
