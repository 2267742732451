@import "../../styles/variables.scss";

#formCreateEvent {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  background-color: $main-bg-color;
  padding: 20px 0;
  margin: 10px 0;

  &.close {
    display: none;
  }
}

.formTitle {
  font-size: 16px;
  color: $main-input-color;
  line-height: 20px;
  margin-bottom: 35px;
  padding: 0 20px;
}

.formBody {
  display: flex;
  flex-direction: column;
  height: calc(
    100% - 10px /*top-margin*/ - 20px /*title*/ - 55px /*title-amrgins*/ - 20px
      /*desired bottom padding*/
  );
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 20px;

  &::-webkit-scrollbar {
    background-color: #fff;
    width: 5px;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #2c2c2c;
  }
}

.radioButtons {
  border: 1px solid $main-input-bg-color;
  border-radius: 4px;
  margin-top: 15px;
  padding: 15px;

  .radio:last-child {
    margin-bottom: 0;
  }

  .title {
    font-size: 12px;
    padding: 0 5px;
    margin-left: -5px;
    color: $main-label-color;
  }
}

.submit {
  background-color: $main-input-color;
  color: #ffffff;
  text-align: center;
  border: none;
  border-radius: 4px;
  line-height: 20px;
  font-size: 14px;
  font-family: MAIN;
  font-weight: bold;
  padding: 10px 0;
  cursor: pointer;
  margin-top: 20px;
  transition: 0.3s;

  &:disabled {
    background-color: #d6d6d6;
    color: #000;
    cursor: no-drop;
  }
}

.openBtn {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
  &::before {
    content: "";
    background-image: url("../../../public/close.svg");
    background-position: center;
    background-size: contain;
    width: 50%;
    height: 50%;
    transform: rotate(45deg);
    transition: 0.3s;
  }

  &.closeBtn {
    &::before {
      transform: rotate(0deg);
    }
  }
}

.address {
  width: 300px;
  border-radius: 12px;
  border: none;
  text-transform: lowercase;
  padding: 10px 15px;
  line-height: 20px;
  font-size: 14px;
  margin-left: 10px;
  color: $main-label-color;
  background: #ffffff;
  border-radius: 12px;
}

.header {
  display: flex;
}

.wrapper {
  max-height: max-content;

  @media (min-width: 1024px) and (min-height: 760px) {
    max-height: 70%;
  }

  overflow: hidden;
  border-radius: 12px;
  z-index: 10000;
}

.formError {
  color: $main-error-color;
  margin-top: 16px;
}
