@import "../../styles/variables.scss";

.times {
  border: 1px solid $main-input-color;
  border-radius: 4px;
  margin: 10px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px;
}

.time {
  width: var(--rdp-cell-size);
  height: calc(var(--rdp-cell-size) - 10px);
  margin: 5px 0;

  .timeLabel {
    font-family: MAIN;
    font-weight: bolder;
    margin: 5px 0;
    font-size: 13px;
    cursor: pointer;
    padding: 2px;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    height: 100%;
  }


  input[type='radio'].timeRadio {
    display: none;

    &:checked+.timeLabel {
      background-color: #000;
      color: #fff;
    }

    &:disabled+.timeLabel {
      color: #808080;
      cursor: default;
    }
  }

  &:hover .timeRadio:not(:disabled, :checked)+.timeLabel {
    background-color: var(--rdp-background-color);
  }
}

.calendarDate {
  position: relative;
}

.calendarInput {
  text-align: center;
  font-size: 14px;
  font-family: MAIN;
  font-weight: bold;
  line-height: 20px;
  text-transform: lowercase;
  outline: 1px solid $main-input-color  !important;
}

.calendarLabel {
  color: $main-input-color;
}

.arrow {
  width: 10px;
  height: 10px;
  border-bottom-left-radius: 2px;
  border: 1px solid $main-input-color;
  border-right: none;
  border-top: none;
  cursor: pointer;
}

.arrow.left {
  transform: rotateZ(45deg);
}

.arrow.right {
  transform: rotateZ(-135deg);
}

.arrow.down {
  transform: rotateZ(-45deg);
}

.nav {
  position: absolute;
  top: 55%;
  bottom: auto;
  margin: 0 15px;
}

.nav.right {
  right: 0;
}

.selectArrow {
  margin-right: 10px;
}

.selectLabel {
  position: absolute;
  top: 3px;
  font-size: 12px;
  background-color: #fff;
  left: 10px;
  padding: 0 5px;
}

.selectContainer {
  position: relative;
}
