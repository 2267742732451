@import "../../styles/variables.scss";

$defaul-radio-color: #000;

.wrapper {
  position: relative;
}

@media (min-height: 530px) {
  .wrapper {
    margin-bottom: 15px;
  }
}

@media (min-height: 390px) and (max-height: 529px) {
  .wrapper {
    margin-bottom: 1vw;
  }
}

input[type="checkbox"].checkbox {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 4px;
  left: 0;
  opacity: 0;

  &:checked+.label:after {
    opacity: 1;
  }

  &:disabled+.label:before {
    filter: grayscale(100%);
  }
}

.label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 26px;
  margin-right: 0;
  line-height: 18px;
  user-select: none;

  &:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $defaul-radio-color;
    border-width: 1px;
    border-style: solid;
    border-color: $defaul-radio-color;
  }

  &.antiWar:before {
    background-color: $antiwar-protest-color;
    border-color: $antiwar-protest-color;
  }

  &.politicalDemonstrations:before {
    background-color: $political-demonstration-color;
    border-color: $political-demonstration-color;
  }

  &.mixed:before {
    background-color: $mixed-protest-color;
    border-color: $mixed-protest-color;
  }

  &.supportEvent:before {
    background-color: $support-event-color;
    border-color: $support-event-color;
  }

  &.showPast:before {
    background-color: $secondary-label-color;
    border-color: $secondary-label-color;
  }

  &:after {
    content: "";
    opacity: 0;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 3px;
    bottom: 3px;
    border-radius: 50%;
    background-color: transparent;
    background-image: url("../../../public/check.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover:before {
    filter: brightness(120%);
  }

  &:hover:after {
    filter: brightness(120%);
  }
}

// .antiWar {
//     .label {
//         &:before {
//             background-color: $antiwar-protest-color;
//             border-color: $antiwar-protest-color;
//         }
//     }
// }

// .politicalDemonstrations {
//     .label {
//         &:after {
//             background-color: $political-demonstration-color;
//         }

//         &:before {
//             background-color: $political-demonstration-color;
//             border-color: $political-demonstration-color;
//         }
//     }
// }

// .mixed {
//     .label {
//         &:after {
//             background-color: $mixed-protest-color;
//         }

//         &:before {
//             background-color: $mixed-protest-color;
//             border-color: $mixed-protest-color;
//         }
//     }
// }
