@import "./variables.scss";

@font-face {
  font-family: "MAIN";
  src: url("../../public/Normalidad-Wide-Light-Desktop.woff");
}

@font-face {
  font-family: "MAIN BOLD";
  src: url("../../public/Normalidad-Extended-Bold-Desktop.woff");
}

@font-face {
  font-family: "MAIN SEMI BOLD";
  src: url("../../public/NormalidadText-Bold.woff");
}

@font-face {
  font-family: "LOGO";
  src: url("../../public/Normalidad-UltraExtended-Bold-Desktop.woff");
}

:root {
  --rdp-cell-size: calc((340px - ((20px + 5px) * 2)) / 7);
  --rdp-accent-color: #000;
  --rdp-background-color: rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "MAIN";
  background-color: gray;
}

.rdp {
  margin: 10px 0 0;
  border: 1px solid #2c2c2c;
  border-radius: 4px;
  padding: 5px;
}

.rdp-caption {
  display: none;
}

.rdp-weeknumber,
.rdp-day {
  border: none;
}

.rdp-day {
  border-radius: 4px;
  align-items: flex-start;
  padding-top: 5px;
  font-family: MAIN;
  font-weight: bolder;
  font-size: 14px;
}

.rdp-head_cell {
  text-transform: lowercase;
}

.flex-container {
  display: flex;
  margin: 10px -5px;
  height: fit-content;
}
.flex-container .column {
  padding: 7px;
  font-family: MAIN;
  flex: 1;
  overflow: hidden;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 0 5px;
  position: relative;
}

.popover-suffix {
  opacity: 0.7;
  text-transform: lowercase;
  font-family: MAIN;
  font-size: 13px;
  display: block;
}
.popover-suffix-up {
  margin-top: -5px;
}
.popover-stat {
  display: block;
  font-size: 14px;
  font-family: "MAIN";
  font-weight: bold;
}
.popover-substat {
  font-size: 1.1em;
  font-family: "MAIN";
}
.lift-metric {
  display: block;
}
.vertical-top-bottom {
  position: absolute;
  bottom: 10px;
}
.stat-pct {
  font-size: 0.9em;
  font-family: "MAIN";
  opacity: 0.9;
  display: block;
}

.leaflet-container a.leaflet-popup-close-button {
  right: 2px;
  font-size: 30px;
}
